import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Hotel extends BaseModel {
    fields =  {
      'name': {
        type: 'text',
        label: 'nama',
        rules: 'required'
      },
      'city': {
        type: 'text',
        label: 'Kota',
        rules: 'required'
      },
      'star': {
        type: 'text',
        label: 'Rating',
        rules: 'required'
      },
      'webiste': {
        type: 'text',
        rules: null
      },
      'google_review': {
        type: 'text',
        label: 'Google Review',
        rules: null
      },
      'is_active': {
        label: 'Status',
        type: 'enum',
        options: {
          0: 'Tidak Aktif',
          1: 'Aktif'
        },
        rules: 'required'
      }
    }
    endpoint = process.env.VUE_APP_API_URL + 'hotels'
}